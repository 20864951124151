
import React, { useState, useEffect, useContext } from 'react';
import { Box, VStack, Heading, Text } from '@chakra-ui/react';
import SnippetCard from './SnippetCard';
import { AuthContext } from '../context/AuthContext';
import { API_BASE_URL } from '../config';

function BookmarkedSnippets() {
  const [snippets, setSnippets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { token } = useContext(AuthContext);

  useEffect(() => {
    const fetchBookmarkedSnippets = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/bookmarked_snippets`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch bookmarked snippets');
        }
        const data = await response.json();
        setSnippets(data.posts);
      } catch (error) {
        console.error('Error fetching bookmarked snippets:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBookmarkedSnippets();
  }, [token]);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  return (
    <Box p={5}>
      <Heading mb={4}>Your Bookmarked Snippets</Heading>
      <VStack spacing={4} align="stretch">
        {snippets.map(snippet => (
          <SnippetCard key={snippet.id} snippet={snippet} />
        ))}
      </VStack>
      {snippets.length === 0 && <Text>You haven't bookmarked any snippets yet.</Text>}
    </Box>
  );
}

export default BookmarkedSnippets;