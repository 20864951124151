import React, { useState, useEffect } from 'react';
import { Box, Button, Text, HStack, VStack } from '@chakra-ui/react';
import SnippetCard from './SnippetCard';
import Loader from './Loader';
import { API_BASE_URL } from '../config';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

function SnippetList({ searchQuery, timeFilter, selectedCategories, filterSnippets, onOpenLoginModal }) {
  const [snippets, setSnippets] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const { token } = useContext(AuthContext);

  const fetchSnippets = async () => {
    setIsLoading(true);
    try {
      let url;
      let queryParams;

      if (searchQuery) {
        url = `${API_BASE_URL}/search`;
        queryParams = new URLSearchParams({
          keyword: searchQuery
        });
      } else {
        url = `${API_BASE_URL}/snippets`;
        queryParams = new URLSearchParams({
          page: page.toString(),
          per_page: '10',
          time_filter: timeFilter || 'all',
          categories: selectedCategories.length > 0 ? selectedCategories.join(',') : ''
        });
      }

      console.log('Fetching snippets with params:', queryParams.toString());

      const response = await fetch(`${url}?${queryParams.toString()}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch snippets');
      }
      const data = await response.json();
      console.log('Received data:', data);
      
      if (searchQuery) {
        setSnippets(data.posts);
        setTotalPages(1); // Set to 1 as search doesn't support pagination in this implementation
      } else {
        setSnippets(Array.isArray(data.posts) ? data.posts : []);
        setTotalPages(Math.ceil(data.total / data.per_page));
      }
    } catch (error) {
      console.error('Error fetching snippets:', error);
      setSnippets([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSnippets();
  }, [page, searchQuery, timeFilter, selectedCategories, token]);

  const filteredSnippets = snippets.filter(snippet => {
    const searchLower = searchQuery.toLowerCase();
    return (
      (snippet.snippet_title && snippet.snippet_title.toLowerCase().includes(searchLower)) ||
      (snippet.snippet && snippet.snippet.toLowerCase().includes(searchLower))
    );
  });

  const timeFilteredSnippets = filterSnippets(filteredSnippets);

  if (isLoading) {
    return (
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        height="calc(100vh - 80px)"
      >
        <Loader />
      </Box>
    );
  }

  return (
    <Box 
      maxWidth={{ base: "100%", md: "800px" }}
      margin="auto"
      width="100%"
    >
      {timeFilteredSnippets.map(snippet => (
        <Box 
          key={snippet.id} 
          mb={4} 
          boxShadow="lg" 
          borderRadius="md" 
          transition="all 0.3s"
          _hover={{
            transform: "translateY(-5px)",
            boxShadow: "xl"
          }}
        >
          <SnippetCard 
            key={snippet.id} 
            snippet={snippet} 
            onOpenLoginModal={onOpenLoginModal}
            refreshSnippets={fetchSnippets}
          />
        </Box>
      ))}
      
      {/* Pagination */}
      <HStack spacing={4} justify="center" mt={4}>
        <Button 
          onClick={() => setPage(prev => Math.max(prev - 1, 1))} 
          disabled={page === 1}
        >
          Previous
        </Button>
        <Text>Page {page} of {totalPages}</Text>
        <Button 
          onClick={() => setPage(prev => Math.min(prev + 1, totalPages))} 
          disabled={page === totalPages}
        >
          Next
        </Button>
      </HStack>
    </Box>
  );
}

export default SnippetList;