import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { 
    Box, VStack, Heading, Text, Spinner, Grid, GridItem, 
    UnorderedList, ListItem, Button, Icon, Flex, Image,
    Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { FaLightbulb, FaExclamationCircle, FaRocket, FaInfoCircle } from 'react-icons/fa';
import SnippetCard from './SnippetCard';
import { API_BASE_URL } from '../config';

// Custom component for formatted markdown
function FormattedMarkdown({ content }) {
    const lines = content.split('\n');
    let indentLevel = 0;

    return (
        <Box>
            {lines.map((line, index) => {
                if (line.startsWith('## ')) {
                    indentLevel = 0;
                    return <Heading as="h3" size="lg" mt={4} mb={2} key={index}>{line.slice(3)}</Heading>;
                } else if (line.startsWith('### ')) {
                    indentLevel = 0;
                    return <Heading as="h4" size="md" mt={3} mb={2} key={index}>{line.slice(4)}</Heading>;
                } else if (line.trim().startsWith('- ')) {
                    const spaces = line.match(/^\s*/)[0].length;
                    indentLevel = Math.floor(spaces / 2);
                    return (
                        <Text key={index} pl={4 + indentLevel * 4} mb={2}>
                            • {formatBoldText(line.trim().slice(2))}
                        </Text>
                    );
                } else if (line.trim() === '') {
                    return <Box key={index} h={2} />;  // Empty line
                } else {
                    indentLevel = 0;
                    return <Text key={index} mb={2}>{formatBoldText(line)}</Text>;
                }
            })}
        </Box>
    );
}

// Helper function to format bold text
function formatBoldText(text) {
    const parts = text.split(/(\*\*.*?\*\*)/g);
    return parts.map((part, index) => {
        if (part.startsWith('**') && part.endsWith('**')) {
            return <strong key={index}>{part.slice(2, -2)}</strong>;
        }
        return part;
    });
}

function CollapsibleSection({ title, items, icon }) {
    const [isExpanded, setIsExpanded] = useState(false);
    const displayItems = isExpanded ? items : items.slice(0, 4);

    return (
        <Box>
            <UnorderedList styleType="none" ml={0}>
                {displayItems.map((item, index) => (
                    <ListItem key={index} mb={2}>
                        <Flex alignItems="center">
                            <Icon as={icon} color="white" mr={2} boxSize={3} />
                            <Text fontSize="sm">{item}</Text>
                        </Flex>
                    </ListItem>
                ))}
            </UnorderedList>
            {items.length > 4 && (
                <Button 
                    onClick={() => setIsExpanded(!isExpanded)} 
                    size="sm" 
                    mt={2}
                    rightIcon={isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
                >
                    {isExpanded ? 'Show Less' : 'Show More'}
                </Button>
            )}
        </Box>
    );
}

function CollapsibleContent({ content, maxLines = 5 }) {
    const [isExpanded, setIsExpanded] = useState(false);
    const lines = content.split('\n');
    const displayContent = isExpanded ? content : lines.slice(0, maxLines).join('\n');

    return (
        <Box>
            <FormattedMarkdown content={displayContent} />
            {lines.length > maxLines && (
                <Button 
                    onClick={() => setIsExpanded(!isExpanded)} 
                    size="sm" 
                    mt={2}
                    rightIcon={isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
                >
                    {isExpanded ? 'Show Less' : 'Show More'}
                </Button>
            )}
        </Box>
    );
}

function VideoSnippetsPage() {
    const [videoData, setVideoData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { videoId } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            console.log('Fetching data for videoId:', videoId);
            try {
                const url = `${API_BASE_URL}/video_page?video_id=${videoId}`;
                console.log('Fetching from URL:', url);
                const response = await fetch(url);

                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                const data = await response.json();
                console.log('Received data:', data);
                if (data.posts && data.posts.length > 0) {
                    console.log('Thumbnail URL:', data.posts[0].thumbnail_url);
                }
                setVideoData(data);
            } catch (err) {
                console.error('Error fetching data:', err);
                setError('Failed to load data. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        if (videoId) {
            fetchData();
        }
    }, [videoId]);

    if (loading) return <Spinner />;
    if (error) return <Text color="red.500">{error}</Text>;
    if (!videoData || !videoData.posts || videoData.posts.length === 0) return <Text>No data available.</Text>;

    const { posts, video_info } = videoData;

    const hasAdditionalInfo = video_info && (
        video_info.summary?.length > 0 ||
        video_info.ideas?.length > 0 ||
        video_info.opportunities?.length > 0 ||
        video_info.problems?.length > 0 ||
        video_info.tools?.length > 0
    );

    return (
        <Box p={5}>
            <Grid templateColumns={hasAdditionalInfo ? "repeat(3, 1fr)" : "1fr"} gap={6}>
                <GridItem colSpan={hasAdditionalInfo ? 2 : 1}>
                    <Heading size="lg" mb={2}>{posts[0].video_title}</Heading>
                    <Text fontSize="md" color="gray.500" mb={4}>{posts[0].channel_name}</Text>
                    <VStack align="stretch" spacing={6}>
                        {video_info?.summary && (
                            <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={4}>
                                <Heading size="xl" mb={4}>Smart Summary</Heading>
                                <CollapsibleContent content={video_info.summary} maxLines={5} />
                            </Box>
                        )}
                        
                        <Box>
                            <Heading size="md" mb={4}>Snippets</Heading>
                            {posts.map(post => (
                                <SnippetCard key={post.id} snippet={post} />
                            ))}
                        </Box>
                    </VStack>
                </GridItem>
                
                {hasAdditionalInfo && (
                    <GridItem>
                        {posts[0].thumbnail && (
                            <Box mb={4}>
                                <Image 
                                    src={posts[0].thumbnail} 
                                    alt={posts[0].video_title}
                                    objectFit="cover"
                                    width="100%"
                                    borderRadius="md"
                                />
                            </Box>
                        )}
                        <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
                            <Accordion allowMultiple>
                                {video_info?.ideas && video_info.ideas.length > 0 && (
                                    <AccordionItem>
                                        <h2>
                                            <AccordionButton>
                                                <Box flex="1" textAlign="left">
                                                    <Icon as={FaLightbulb} color="white" mr={2} boxSize={4} />
                                                    Ideas
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4}>
                                            <CollapsibleSection 
                                                items={video_info.ideas} 
                                                icon={FaLightbulb}
                                            />
                                        </AccordionPanel>
                                    </AccordionItem>
                                )}
                                {video_info?.opportunities && video_info.opportunities.length > 0 && (
                                    <AccordionItem>
                                        <h2>
                                            <AccordionButton>
                                                <Box flex="1" textAlign="left">
                                                    <Icon as={FaRocket} color="white" mr={2} boxSize={4} />
                                                    Opportunities
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4}>
                                            <CollapsibleSection 
                                                items={video_info.opportunities} 
                                                icon={FaRocket}
                                            />
                                        </AccordionPanel>
                                    </AccordionItem>
                                )}
                                {video_info?.problems && video_info.problems.length > 0 && (
                                    <AccordionItem>
                                        <h2>
                                            <AccordionButton>
                                                <Box flex="1" textAlign="left">
                                                    <Icon as={FaExclamationCircle} color="white" mr={2} boxSize={4} />
                                                    Problems Discussed
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4}>
                                            <CollapsibleSection 
                                                items={video_info.problems} 
                                                icon={FaExclamationCircle}
                                            />
                                        </AccordionPanel>
                                    </AccordionItem>
                                )}
                                {video_info?.tools && video_info.tools.length > 0 && (
                                    <AccordionItem>
                                        <h2>
                                            <AccordionButton>
                                                <Box flex="1" textAlign="left">
                                                    <Icon as={FaInfoCircle} color="white" mr={2} boxSize={4} />
                                                    Tools & Topics
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4}>
                                            <Box borderWidth="1px" borderRadius="lg" p={4}>
                                                <Heading size="md" mb={2}>Tools & Topics</Heading>
                                                <UnorderedList styleType="none" ml={0}>
                                                    {video_info.tools.map((tool, index) => (
                                                        <ListItem key={index} mb={2}>
                                                            <Flex alignItems="center">
                                                                <Icon as={FaInfoCircle} color="white" mr={2} boxSize={3} />
                                                                <Text fontSize="sm">{tool}</Text>
                                                            </Flex>
                                                        </ListItem>
                                                    ))}
                                                </UnorderedList>
                                            </Box>
                                        </AccordionPanel>
                                    </AccordionItem>
                                )}
                            </Accordion>
                        </Box>
                    </GridItem>
                )}
            </Grid>
        </Box>
    );
}

export default VideoSnippetsPage;