import React, { useState, useCallback, useContext, useEffect } from 'react';
import { Box, Container, Flex, useDisclosure } from '@chakra-ui/react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import LoginSignupModal from './LoginSignupModal';
import HomePage from '../pages/HomePage';
import SnippetPage from '../pages/SnippetPage';
import { AuthContext } from '../context/AuthContext';
import NavBar from './NavBar';
import VideoSnippetsPage from './VideoSnippetsPage';
import ChannelPage from './ChannelPage';
import InterestsModal from './InterestsModal';
import BookmarkedSnippets from './BookmarkedSnippets';
import { API_BASE_URL } from '../config';
import LandingPage from './LandingPage';

function AppContent() {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [timeFilter, setTimeFilter] = useState('all');
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const { isAuthenticated, login, logout, token } = useContext(AuthContext);
  const [showInterestsModal, setShowInterestsModal] = useState(false);
  const [snippets, setSnippets] = useState([]);
  const { isOpen: isLoginOpen, onOpen: onOpenLogin, onClose: onCloseLogin } = useDisclosure();
  const { isOpen: isSignupOpen, onOpen: onOpenSignup, onClose: onCloseSignup } = useDisclosure();

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      login(storedToken);
      fetchSnippets(storedToken);
    }
  }, [login]);

  const fetchSnippets = useCallback(async (token) => {
    try {
      const response = await fetch(`${API_BASE_URL}/snippets`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setSnippets(data.posts);
      } else if (response.status === 401) {
        console.error('Unauthorized access when fetching snippets');
        logout();
      }
    } catch (error) {
      console.error('Error fetching snippets:', error);
    }
  }, [logout]);

  const handleLogin = useCallback(async (email, password) => {
    try {
      const response = await fetch(`${API_BASE_URL}/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });
      if (response.ok) {
        const data = await response.json();
        login(data.token);
        localStorage.setItem('token', data.token);
        await fetchSnippets(data.token);
        navigate('/');
      } else {
        throw new Error('Login failed');
      }
    } catch (error) {
      console.error('Login error:', error);
      // Show an error message to the user
    }
  }, [login, fetchSnippets, navigate]);

  const handleSignup = async (email, password, username) => {
    try {
      const response = await fetch(`${API_BASE_URL}/register`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, username }),
      });
      if (response.ok) {
        const data = await response.json();
        login(data.token);
        localStorage.setItem('token', data.token);
        setShowInterestsModal(true);
        navigate('/');
      } else {
        throw new Error('Signup failed');
      }
    } catch (error) {
      console.error('Signup error:', error);
      // Show an error message to the user
    }
  };

  const handleInterestsSubmit = async (interests) => {
    try {
      const response = await fetch(`${API_BASE_URL}/interests`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ interests }),
      });
      setShowInterestsModal(false);
      fetchSnippets(token);
    } catch (error) {
      console.error('Error submitting interests:', error);
    }
  };

  const handleCategoryChange = (category) => {
    setSelectedCategories(prev => 
      prev.includes(category) 
        ? prev.filter(c => c !== category)
        : [...prev, category]
    );
  };

  const filterSnippets = useCallback((snippets) => {
    return snippets.filter(snippet => {
      if (!snippet.upload_date) return true;
      const uploadDate = new Date(snippet.upload_date);
      // Add your filtering logic here
      return true; // or your actual filtering condition
    });
  }, [/* add dependencies if needed */]);

  return (
    <Flex direction="column" minHeight="100vh">
      {isAuthenticated ? (
        <>
          <NavBar 
            isAuthenticated={isAuthenticated}
            onLogout={logout}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            selectedCategories={selectedCategories}
            handleCategoryChange={handleCategoryChange}
            onOpenLoginModal={onOpenLogin}
          />
          <Box 
            as="main" 
            flex={1} 
            mt="80px" 
            pt={8}
          >
            <Container maxW="80%">
              <Routes>
                <Route path="/" element={
                  <HomePage 
                    searchQuery={searchQuery}
                    timeFilter={timeFilter}
                    categories={categories}
                    selectedCategories={selectedCategories}
                    handleCategoryChange={handleCategoryChange}
                    filterSnippets={filterSnippets}
                    setCategories={setCategories}
                    snippets={snippets}
                  />
                } />
                <Route path="/snippet/:id" element={<SnippetPage />} />
                <Route path="/video_page/:videoId" element={<VideoSnippetsPage />} />
                <Route path="/channel/:channelName" element={<ChannelPage />} />
                <Route path="/bookmarked" element={<BookmarkedSnippets />} />
              </Routes>
            </Container>
          </Box>
        </>
      ) : (
        <LandingPage onOpenLoginModal={onOpenLogin} onOpenSignupModal={onOpenSignup} />
      )}
      <InterestsModal isOpen={showInterestsModal} onClose={() => setShowInterestsModal(false)} onSubmit={handleInterestsSubmit} />
      <LoginSignupModal 
        isOpen={isLoginOpen || isSignupOpen} 
        onClose={() => {
          onCloseLogin();
          onCloseSignup();
        }} 
        onLogin={handleLogin}
        onSignup={handleSignup}
        initialMode={isSignupOpen ? 'signup' : 'login'}
      />
    </Flex>
  );
}

export default AppContent;