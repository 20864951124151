import React, { useState } from 'react';
import { Box, Button, Container, Flex, Heading, Text, VStack, Grid, Icon, HStack, Stack, IconButton } from '@chakra-ui/react';
import { ChevronRightIcon, HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { FaHeadphones, FaLightbulb, FaShareAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function LandingPage({ onOpenLoginModal, onOpenSignupModal }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <Box 
      minHeight="100vh" 
      color="white" 
      position="relative"
      _before={{
        content: '""',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: `
          radial-gradient(circle at center, rgba(255,255,255,0.2) 0, rgba(255,255,255,0.2) 1px, transparent 1px),
          linear-gradient(to top, rgba(255, 255, 0, 0.1) 0%, rgba(0, 0, 0, 0) 30%),
          black
        `,
        backgroundSize: '24px 24px, 100% 100%',
        zIndex: -1,
      }}
    >
      <Container 
        maxW="container.xl" 
        position="relative" 
        zIndex="1"
        px={{ base: 4, md: 6 }} // Add padding for mobile screens
      >
        <Flex as="header" py={4} justifyContent="space-between" alignItems="center">
          <Heading as="h1" size="lg">Podlimes</Heading>
          
          {/* Desktop Navigation */}
          <Flex display={{ base: "none", md: "flex" }} alignItems="center" justifyContent="center" flex={1}>
            <Box 
              border="1px" 
              borderColor="white" 
              borderRadius="full"
              p={1}
            >
              <Stack direction="row" spacing={0}>
                {["Features", "Pricing", "Contact"].map((item, index) => (
                  <Button
                    key={index}
                    as={Link}
                    to={`/${item.toLowerCase()}`}
                    variant="ghost"
                    color="white"
                    _hover={{ bg: 'white', color: 'black' }}
                    transition="all 0.2s"
                    borderRadius="full"
                    borderRight={index < 2 ? "1px solid rgba(255,255,255,0.2)" : "none"}
                    fontSize="sm"
                    py={2}
                    px={4}
                  >
                    {item}
                  </Button>
                ))}
              </Stack>
            </Box>
          </Flex>

          {/* Login/Signup Buttons */}
          <HStack spacing={4} display={{ base: "none", md: "flex" }}>
            <Button variant="outline" color="white" onClick={onOpenLoginModal}>Log in</Button>
            <Button variant="outline" color="white" onClick={onOpenSignupModal}>Sign up</Button>
          </HStack>

          {/* Mobile Navigation */}
          <Flex display={{ base: "flex", md: "none" }} alignItems="center">
            <HStack spacing={4}>
              <Button variant="outline" color="white" onClick={onOpenLoginModal} size="sm">Log in</Button>
              <Button variant="outline" color="white" onClick={onOpenSignupModal} size="sm">Sign up</Button>
            </HStack>
            <IconButton
              aria-label="Open Menu"
              size="lg"
              mr={2}
              icon={<HamburgerIcon />}
              onClick={toggleMenu}
              display={{ base: "flex", md: "none" }}
            />
          </Flex>
        </Flex>

        {/* Mobile Menu */}
        <Box
          display={{ base: isOpen ? "block" : "none", md: "none" }}
          position="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bg="black"
          zIndex={20}
          p={4}
        >
          <Flex justifyContent="flex-end">
            <IconButton
              aria-label="Close Menu"
              size="lg"
              icon={<CloseIcon />}
              onClick={toggleMenu}
            />
          </Flex>
          <VStack spacing={4} align="stretch" mt={8}>
            {["Features", "Pricing", "Contact"].map((item, index) => (
              <Button
                key={index}
                as={Link}
                to={`/${item.toLowerCase()}`}
                variant="ghost"
                color="white"
                onClick={toggleMenu}
              >
                {item}
              </Button>
            ))}
          </VStack>
        </Box>

        <VStack as="main" spacing={12} py={{ base: 10, md: 20 }} textAlign="center">
          <Button variant="outline" size="sm">
            ✨ Introducing Podlimes <ChevronRightIcon ml={2} />
          </Button>
          <Heading as="h2" size={{ base: "2xl", md: "4xl" }} lineHeight="shorter">
            Turn YouTube noise into your next viral idea!
          </Heading>
          <Text maxW="2xl" fontSize={{ base: "lg", md: "xl" }} color="gray.400">
            Podlimes turns videos and podcasts into actionable insights. 
            Discover, understand, and share knowledge from your favorite audio content.
          </Text>
          
          <Button colorScheme="white" color="white" size="lg" onClick={onOpenSignupModal} width={{ base: "100%", md: "auto" }}>
            Start Grokking <ChevronRightIcon ml={2} />
          </Button>
        </VStack>

        <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={8} py={{ base: 10, md: 20 }}>
          {[
            { icon: FaHeadphones, title: "Discover", description: "Find new podcasts and episodes based on your interests and listening history." },
            { icon: FaLightbulb, title: "Grok", description: "Our AI extracts key insights, snippets, ideas, and opportunities from each episode." },
            { icon: FaShareAlt, title: "Share", description: "Easily share insights with your network or save them for later reference." }
          ].map((item, index) => (
            <VStack key={index} align="center" textAlign="center">
              <Icon as={item.icon} boxSize={12} color="lime.500" />
              <Heading as="h3" size="lg">{item.title}</Heading>
              <Text color="gray.400">{item.description}</Text>
            </VStack>
          ))}
        </Grid>

        <Box 
          as="section" 
          bg="black" 
          py={{ base: 10, md: 20 }} 
          mt={{ base: 10, md: 20 }} 
          px={8}
          borderRadius="2xl"
          border="1px solid white"
        >
          <VStack spacing={8} mb={12}>
            <Heading as="h2" size={{ base: "xl", md: "2xl" }}>How PodLimes Works</Heading>
            <Text color="gray.400" maxW="2xl" textAlign="center">
              Transform your podcast listening experience into a journey of discovery and understanding.
            </Text>
          </VStack>
          <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={8}>
            {[
              { title: "1. Choose Your Podcast", description: "Select from our vast library or import your favorite shows." },
              { title: "2. AI Analysis", description: "Our advanced AI breaks down the content into digestible insights." },
              { title: "3. Explore Insights", description: "Dive into key takeaways, summaries, and related content suggestions." }
            ].map((item, index) => (
              <Box key={index} bg="gray.800" p={6} borderRadius="lg" boxShadow="0 0 10px rgba(255,255,255,0.1)">
                <Heading as="h4" size="md" mb={2}>{item.title}</Heading>
                <Text color="gray.400">{item.description}</Text>
              </Box>
            ))}
          </Grid>
        </Box>

        <Flex as="footer" py={6} borderTop="1px" borderColor="gray.800" flexDirection={{ base: "column", md: "row" }} justifyContent="space-between" alignItems="center">
          <Text color="gray.400" fontSize="sm" mb={{ base: 4, md: 0 }}>© 2024 PodLimes. All rights reserved.</Text>
          <Flex gap={4}>
            {["Terms", "Privacy", "Contact"].map((item, index) => (
              <Link key={index} to="#" className="text-sm text-gray-400 hover:text-white">
                {item}
              </Link>
            ))}
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
}

export default LandingPage;