import React, { createContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const [bookmarks, setBookmarks] = useState([]);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      const decodedToken = jwtDecode(storedToken);
      setIsAuthenticated(true);
      setToken(storedToken);
      setUserId(decodedToken.user_id);
    }
  }, []);

  const login = (newToken) => {
    const decodedToken = jwtDecode(newToken);
    setIsAuthenticated(true);
    setToken(newToken);
    setUserId(decodedToken.user_id);
    localStorage.setItem('token', newToken);
  };

  const logout = () => {
    setIsAuthenticated(false);
    setToken(null);
    setUserId(null);
    setBookmarks([]);
    localStorage.removeItem('token');
  };

  const updateBookmarks = (newBookmarks) => {
    setBookmarks(newBookmarks);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, token, userId, login, logout, bookmarks, updateBookmarks }}>
      {children}
    </AuthContext.Provider>
  );
};