import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, VStack, Heading, Text, Button, Input, useToast, HStack, Tag, Image, Divider, Card, CardBody, CardHeader, Flex, Avatar, useColorModeValue, Container } from '@chakra-ui/react';
import { FaPlay, FaHeart, FaComment, FaShare } from 'react-icons/fa';
import { AuthContext } from '../context/AuthContext';
import { format } from 'date-fns';
import { API_BASE_URL } from '../config';
import './SnippetPage.css';

function SnippetPage() {
  const { id } = useParams();
  const [snippet, setSnippet] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const { isAuthenticated, token, userId, updateBookmarks } = useContext(AuthContext);
  const toast = useToast();
  const navigate = useNavigate();

  const commentBgColor = useColorModeValue('gray.50', 'gray.700');
  const commentTextColor = useColorModeValue('gray.800', 'white');

  // Add this new constant for border radius
  const cardBorderRadius = { base: "md", md: "lg" };

  useEffect(() => {
    const fetchSnippet = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/post/${id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch snippet');
        }
        const data = await response.json();
        setSnippet(data);
        setComments(data.comments || []);
      } catch (error) {
        console.error('Error fetching snippet:', error);
        toast({
          title: "Error",
          description: "Failed to load snippet data",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };

    fetchSnippet();
  }, [id, toast]);

  const handleLike = async () => {
    if (!isAuthenticated) {
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/like/${id}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setSnippet(prevSnippet => ({
          ...prevSnippet,
          likes: data.message === 'Post liked successfully' 
            ? (prevSnippet.likes || 0) + 1 
            : (prevSnippet.likes || 0) - 1
        }));
        toast({
          title: data.message,
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error liking/unliking snippet:', error);
      toast({
        title: "Error",
        description: "Failed to like/unlike the snippet",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAddComment = async () => {
    if (!isAuthenticated) {
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/comment/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ comment: newComment }),
      });

      if (!response.ok) {
        throw new Error('Failed to add comment');
      }

      const data = await response.json();
      setComments(prevComments => [...prevComments, {
        comment: newComment,
        username: data.username,
        timestamp: data.timestamp
      }]);
      setNewComment('');
      toast({
        title: "Comment added",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error adding comment:', error);
      toast({
        title: "Error",
        description: "Failed to add comment",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleShare = () => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      toast({
        title: "Link copied!",
        description: "The snippet link has been copied to your clipboard.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }).catch(err => {
      console.error('Failed to copy: ', err);
      toast({
        title: "Failed to copy link",
        description: "An error occurred while trying to copy the link.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    });
  };

  if (!snippet) {
    return <Box>Loading...</Box>;
  }

  return (
    <Container maxW="container.xl" py={8}>
      <Card borderRadius={cardBorderRadius}>
        <CardHeader>
          <Heading as="h1" size={{ base: "lg", md: "xl" }}>{snippet.snippet_title}</Heading>
        </CardHeader>
        <CardBody>
          <VStack spacing={6} align="stretch">
            <HStack wrap="wrap">
              <Tag size="md" colorScheme="blue">{snippet.main_category}</Tag>
              {snippet.tags.map((tag, index) => (
                <Tag key={index} size="sm">{tag}</Tag>
              ))}
            </HStack>
            
            <Text fontSize={{ base: "sm", md: "md" }}>{snippet.snippet}</Text>
            
            <Flex justify="space-between" wrap="wrap" gap={2}>
              <Text fontSize={{ base: "xs", md: "sm" }} color="gray.600">
                From: <Text as="span" fontWeight="medium">{snippet.channel_name}</Text>
              </Text>
              <Text fontSize={{ base: "xs", md: "sm" }} color="gray.600">{snippet.video_title}</Text>
            </Flex>
            
            <Image 
              src={snippet.thumbnail} 
              alt={snippet.video_title} 
              maxHeight={{ base: "150px", md: "200px" }} 
              objectFit="contain" 
              borderRadius="md" 
            />
            
            <HStack spacing={2} wrap="wrap" justify="center">
              <Button 
                leftIcon={<FaHeart />} 
                colorScheme="pink" 
                variant="outline" 
                onClick={handleLike}
                _hover={{ bg: 'pink.100' }}
                size={{ base: "sm", md: "md" }}
              >
                Like ({snippet.likes || 0})
              </Button>
              <Button 
                leftIcon={<FaComment />} 
                colorScheme="blue" 
                variant="outline"
                _hover={{ bg: 'blue.100' }}
                size={{ base: "sm", md: "md" }}
              >
                Comment ({comments.length})
              </Button>
              <Button 
                leftIcon={<FaShare />} 
                colorScheme="green" 
                variant="outline" 
                onClick={handleShare}
                _hover={{ bg: 'green.100' }}
                size={{ base: "sm", md: "md" }}
              >
                Share
              </Button>
              <Button 
                leftIcon={<FaPlay />}
                colorScheme="red" 
                as="a"
                href={snippet.timestamp_url}
                target="_blank"
                rel="noopener noreferrer"
                _hover={{ bg: 'red.100' }}
                size={{ base: "sm", md: "md" }}
              >
                Watch
              </Button>
            </HStack>
          </VStack>
        </CardBody>
      </Card>
      
      <Divider my={8} />
      
      {isAuthenticated ? (
        <Card borderRadius={cardBorderRadius}>
          <CardHeader>
            <Heading as="h2" size="lg">Comments</Heading>
          </CardHeader>
          <CardBody>
            {comments.map((comment, index) => (
              <Box 
                key={index} 
                p={4} 
                borderWidth={1} 
                borderRadius={cardBorderRadius} 
                bg={commentBgColor} 
                color={commentTextColor} 
                mb={4}
              >
                <HStack justify="space-between" wrap="wrap">
                  <HStack>
                    <Avatar name={comment.username} size="sm" />
                    <Text fontWeight="bold">{comment.username}</Text>
                  </HStack>
                  <Text fontSize="sm" color="gray.500">
                    {comment.timestamp ? format(new Date(comment.timestamp), 'MMMM d, yyyy h:mm a') : 'Invalid date'}
                  </Text>
                </HStack>
                <Text mt={2}>{comment.comment}</Text>
              </Box>
            ))}
            <Box mt={4}>
              <Input
                placeholder="Add a comment..."
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                mb={2}
                bg="white"
              />
              <Button onClick={handleAddComment} colorScheme="blue">
                Add Comment
              </Button>
            </Box>
          </CardBody>
        </Card>
      ) : (
        <Card borderRadius={cardBorderRadius}>
          <CardHeader>
            <Heading size="md">Comments are only visible to logged-in users</Heading>
          </CardHeader>
          <CardBody>
            <Text>Please log in to view and add comments.</Text>
          </CardBody>
        </Card>
      )}
    </Container>
  );
}

export default SnippetPage;