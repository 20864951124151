import React, { useContext, useState } from 'react';
import { Box, HStack, Input, Container, Flex, Button, useColorMode, useColorModeValue, IconButton, VStack, Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, useDisclosure } from '@chakra-ui/react';
import { MoonIcon, SunIcon, HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { AuthContext } from '../context/AuthContext';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as PodlimesLogo } from '../Podlimes.svg';
import FilterPanel from './FilterPanel';
import './NavBar.css';

const Logo = () => {
  const { colorMode } = useColorMode();
  const LogoComponent = colorMode === 'dark' ? require('../dark_podlimes_logo.svg').ReactComponent : require('../light_podlimes_logo.svg').ReactComponent;
  
  return (
    <Link to="/">
      <Box 
        as={LogoComponent} 
        width={{ base: "120px", md: "180px" }} 
        height={{ base: "40px", md: "48px" }}
        display="flex"
        alignItems="center"
      />
    </Link>
  );
};

const ColorModeToggle = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <IconButton
      onClick={toggleColorMode}
      icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
      size={{ base: "sm", md: "md" }}
      aria-label="Toggle color mode"
    />
  );
};

const NavBar = ({ isAuthenticated, onOpenLoginModal, onLogout, searchQuery, setSearchQuery, selectedCategories, handleCategoryChange }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');
  const inputBgColor = useColorModeValue('white', 'gray.700');

  const SearchInput = () => {
    const [inputValue, setInputValue] = useState(searchQuery);

    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        setSearchQuery(inputValue);
      }
    };

    const handleClear = () => {
      setInputValue('');
      setSearchQuery('');
    };

    return (
      <Flex width="100%" maxWidth={{ base: "200px", md: "500px" }} position="relative">
        <Input 
          placeholder="Search podcasts..." 
          onKeyPress={handleKeyPress}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          bg={inputBgColor}
          color={textColor}
          size={{ base: "sm", md: "md" }}
          borderRadius="md"
          pr="2rem"
        />
        {inputValue && (
          <IconButton
            icon={<CloseIcon />}
            size={{ base: "xs", md: "sm" }}
            position="absolute"
            right="0.5rem"
            top="50%"
            transform="translateY(-50%)"
            onClick={handleClear}
            aria-label="Clear search"
            bg="transparent"
            _hover={{ bg: 'transparent' }}
            _active={{ bg: 'transparent' }}
            color="gray.500"
          />
        )}
      </Flex>
    );
  };

  return (
    <Box 
      position="fixed" 
      top={0} 
      left={0} 
      right={0} 
      zIndex="sticky" 
      boxShadow="md" 
      bg={bgColor}
      color={textColor}
    >
      <Container maxW="container.xl" py={{ base: 2, md: 3 }}>
        <Flex justify="space-between" align="center">
          <Logo />
          <Flex 
            justify="flex-end"
            align="center" 
            flex={1}
          >
            <HStack spacing={{ base: 2, md: 3 }}>
              <Box display={{ base: "block", md: "none" }}>
                <SearchInput />
              </Box>
              <Box 
                width={{ base: "auto", md: "400px" }} 
                display={{ base: "none", md: "block" }}
                mr={3}
              >
                <SearchInput />
              </Box>
              <ColorModeToggle />
              {isAuthenticated ? (
                <Button onClick={onLogout}>Logout</Button>
              ) : (
                <Button onClick={onOpenLoginModal}>Login</Button>
              )}
              <IconButton
                icon={<HamburgerIcon />}
                onClick={onOpen}
                aria-label="Open menu"
                display={{ base: 'flex', md: 'none' }}
                size={{ base: "sm", md: "md" }}
                mr={{ base: 2, md: 0 }}
              />
            </HStack>
          </Flex>
        </Flex>
      </Container>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Menu</DrawerHeader>
          <DrawerBody>
            <VStack spacing={4} align="stretch">
              <FilterPanel 
                selectedCategories={selectedCategories}
                onCategoryChange={handleCategoryChange}
                isMobile={true}
              />
              <Button onClick={() => {/* Handle explore */}}>Explore</Button>
              {isAuthenticated ? (
                <Button onClick={onLogout} size="sm">Logout</Button>
              ) : (
                <Button onClick={onOpenLoginModal} size="sm">Login</Button>
              )}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default NavBar;