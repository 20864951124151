import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, VStack, Heading, Text, Spinner } from '@chakra-ui/react';
import SnippetCard from './SnippetCard';
import { API_BASE_URL } from '../config';
import './ChannelPage.css';
function ChannelPage() {
  const [snippets, setSnippets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { channelName } = useParams();

  useEffect(() => {
    const fetchChannelSnippets = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/channel_snippets?channel_name=${encodeURIComponent(channelName)}`);
        if (!response.ok) {
          throw new Error('Failed to fetch channel snippets');
        }
        const data = await response.json();
        setSnippets(data.posts);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchChannelSnippets();
  }, [channelName]);

  if (loading) return <Spinner />;
  if (error) return <Text color="red.500">{error}</Text>;

  return (
    <Box p={5} maxWidth={{ base: "95%", md: "800px" }} margin="auto" width="100%">
      <Heading mb={2}>Snippets from: {channelName}</Heading>
      <Text mb={4} fontSize="lg" fontWeight="medium" color="gray.600">
        {snippets.length} snippet{snippets.length !== 1 ? 's' : ''}
      </Text>
      <VStack spacing={4} align="stretch">
        {snippets.map(snippet => (
          <SnippetCard key={snippet.id} snippet={snippet} />
        ))}
      </VStack>
    </Box>
  );
}

export default ChannelPage;
