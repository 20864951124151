import React, { useState, useEffect, useContext } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Checkbox, VStack, useToast } from '@chakra-ui/react';
import { AuthContext } from '../context/AuthContext';
import { API_BASE_URL } from '../config';

function InterestsModal({ isOpen, onClose }) {
  const [categories, setCategories] = useState([]);
  const [selectedInterests, setSelectedInterests] = useState([]);
  const { token } = useContext(AuthContext);
  const toast = useToast();

  useEffect(() => {
    fetch(`${API_BASE_URL}/categories`)
      .then(response => response.json())
      .then(data => setCategories(data));
  }, []);

  const handleSubmit = async () => {
    if (selectedInterests.length < 3) {
      toast({
        title: "Select at least 3 interests",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/update_interests`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ interests: selectedInterests }),
      });

      if (response.ok) {
        toast({
          title: "Interests updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onClose();
      } else {
        throw new Error('Failed to update interests');
      }
    } catch (error) {
      toast({
        title: "Error updating interests",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select Your Interests</ModalHeader>
        <ModalBody>
          <VStack align="start">
            {categories.map(category => (
              <Checkbox
                key={category}
                isChecked={selectedInterests.includes(category)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedInterests([...selectedInterests, category]);
                  } else {
                    setSelectedInterests(selectedInterests.filter(i => i !== category));
                  }
                }}
              >
                {category}
              </Checkbox>
            ))}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={handleSubmit}>Submit</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default InterestsModal;
