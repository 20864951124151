import React, { useEffect, useState } from 'react';
import { Box, VStack, Text, Checkbox, Heading, useColorModeValue, Button } from '@chakra-ui/react';
import { API_BASE_URL } from '../config';

function FilterPanel({ selectedCategories, onCategoryChange, isMobile = false }) {
  const [allCategories, setAllCategories] = useState([]);
  const [showAllCategories, setShowAllCategories] = useState(false);
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const bgColor = useColorModeValue('white', 'gray.700');

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/categories`);
        if (!response.ok) {
          throw new Error('Failed to fetch categories');
        }
        const data = await response.json();
        setAllCategories(data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const displayedCategories = showAllCategories ? allCategories : allCategories.slice(0, 10);

  return (
    <Box 
      width={isMobile ? "100%" : "250px"} 
      p={4} 
      borderWidth={isMobile ? 0 : 1}
      borderRadius={isMobile ? "none" : "lg"}
      bg={isMobile ? "transparent" : bgColor}
      boxShadow={isMobile ? "none" : "md"}
    >
      <VStack align="start" spacing={4}>
        <Heading size="md">Filter by Category</Heading>
        {displayedCategories.map(category => (
          <Checkbox 
            key={category}
            isChecked={selectedCategories.includes(category)}
            onChange={() => onCategoryChange(category)}
          >
            {category}
          </Checkbox>
        ))}
        {allCategories.length > 10 && (
          <Button 
            onClick={() => setShowAllCategories(!showAllCategories)} 
            size="sm" 
            variant="link"
          >
            {showAllCategories ? "Show less" : "Show more"}
          </Button>
        )}
      </VStack>
    </Box>
  );
}

export default FilterPanel;
