import React from 'react';
import { Grid, GridItem, Box } from '@chakra-ui/react';
import SnippetList from '../components/SnippetList';
import FilterPanel from '../components/FilterPanel';
import FeaturedChannels from '../components/FeaturedChannels';
import './HomePage.css';

function HomePage({ 
  searchQuery, 
  timeFilter, 
  categories, 
  selectedCategories, 
  handleCategoryChange, 
  filterSnippets, 
  setCategories, 
  onOpenLoginModal, 
  snippets 
}) {
  return (
    <Grid templateColumns={{ base: "1fr", md: "250px 1fr 300px" }} gap={6} alignItems="start">
      <GridItem display={{ base: 'none', md: 'block' }}>
        <Box position="sticky" top="100px">
          <FilterPanel 
            selectedCategories={selectedCategories}
            onCategoryChange={handleCategoryChange}
          />
        </Box>
      </GridItem>
      <GridItem>
        <SnippetList 
          searchQuery={searchQuery}
          timeFilter={timeFilter}
          selectedCategories={selectedCategories}
          filterSnippets={filterSnippets}
          setCategories={setCategories}
          onOpenLoginModal={onOpenLoginModal}
          handleCategoryChange={handleCategoryChange}
          snippets={snippets}
        />
      </GridItem>
      <GridItem display={{ base: 'none', md: 'block' }}>
        <Box position="sticky" top="100px">
          <FeaturedChannels />
        </Box>
      </GridItem>
    </Grid>
  );
}

export default HomePage;