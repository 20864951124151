import React, { useState, useEffect } from 'react';
import { Box, Text, keyframes, VStack, useColorModeValue } from '@chakra-ui/react';
import { ReactComponent as LimeSliceBlack } from '../lime-slice-black.svg';
import { ReactComponent as LimeSliceYellow } from '../lime-slice-yellow.svg';

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const Loader = () => {
  const [isBlack, setIsBlack] = useState(true);
  const bgColor = useColorModeValue('white', 'gray.800');

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsBlack(prev => !prev);
    }, 1000); // Toggle every 1 second (adjust as needed)

    return () => clearInterval(intervalId);
  }, []);

  return (
    <VStack spacing={4}>
      <Box
        as={isBlack ? LimeSliceBlack : LimeSliceYellow}
        width="100px"
        height="100px"
        animation={`${spin} 2s linear infinite`}
        bg={bgColor}
        borderRadius="full"
      />
      <Text fontSize="xl" fontWeight="bold">
        Loading...
      </Text>
    </VStack>
  );
};

export default Loader;
