import React, { useState, useEffect } from 'react';
import { VStack, Heading, Text, Spinner, Box, HStack, Avatar } from '@chakra-ui/react';
import { API_BASE_URL } from '../config';
import { Link } from 'react-router-dom';

function FeaturedChannels() {
  const [featuredChannels, setFeaturedChannels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFeaturedChannels = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/featured_channels`);
        if (!response.ok) {
          throw new Error('Failed to fetch featured channels');
        }
        const data = await response.json();
        console.log('Fetched featured channels:', data);
        setFeaturedChannels(data);
      } catch (error) {
        console.error('Error fetching featured channels:', error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFeaturedChannels();
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <Text color="red.500">Error: {error}</Text>;
  }

  return (
    <Box position="sticky" top="100px">
      <Box borderWidth="1px" borderRadius="lg" p={4}>
        <Heading size="md" mb={4}>Explore Podcasts</Heading>
        <VStack align="start" spacing={4} width="100%">
          {featuredChannels && featuredChannels.length > 0 ? (
            featuredChannels.map((channel, index) => (
              <Link to={`/channel/${encodeURIComponent(channel.channel_name)}`} key={index}>
                <HStack spacing={3} width="100%">
                  <Avatar size="sm" name={channel.channel_name} src={channel.avatar_url} />
                  <Box>
                    <Text fontWeight="bold" fontSize="sm">{channel.channel_name}</Text>
                    <Text fontSize="xs" color="gray.600">
                      {channel.snippet_count} snippet{channel.snippet_count !== 1 ? 's' : ''}
                    </Text>
                  </Box>
                </HStack>
              </Link>
            ))
          ) : (
            <Text>No featured channels available</Text>
          )}
        </VStack>
      </Box>
    </Box>
  );
}

export default FeaturedChannels;
