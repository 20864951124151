import React from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { mode } from '@chakra-ui/theme-tools';
import AppContent from './components/AppContent';
import './App.css';

const theme = extendTheme({
  styles: {
    global: (props) => ({
      body: {
        bg: mode('gray.50', 'gray.900')(props),
      },
    }),
  },
  components: {
    Container: {
      baseStyle: {
        maxWidth: { base: "100%", md: "80%" },
        px: { base: 0, md: 4 },
      },
    },
  },
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <AppContent />
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;